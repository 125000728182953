import React from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';

const TaskList = ({ batchTasks }) => {
  const renderStatus = (status) => {
    switch (status) {
      case 0: // Waiting
        return <span className="status-circle yellow-circle"></span>;
      case 1: // Executing
        return <Spinner animation="border" size="sm" />;
      case 2: // Done
        return <span className="status-circle green-circle"></span>;
      case 3: // Done
        return <span className="status-circle red-circle"></span>;
      default:
        return null;
    }
  };
  const reversedBatchTasks = [...batchTasks].reverse();
  return (
    <ListGroup className="listGroup">
      {batchTasks.map((task, index) => (
      <ListGroup.Item key={index} className="listGroupItem">
          <strong className="taskName">
          {renderStatus(task.status)} {task.name} 
          </strong>
          {task.status == 1 ? (
          <div className="taskDetails">
            {Object.entries(task.inputs).map(([key, value]) => (
              <span key={key} className="detailItem">
                <span className="detailKey">{key}:</span>
                {value}
              </span>
            ))}
          </div>):(<></>)}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default TaskList;
