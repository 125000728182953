
const SETTINGS = {
    myWalletsPks : ["0x2a29b8882a387ecc7cbacddbcff613800847c2e0e3acc7db2b81922783ed6142", "0xeffafcaca9abcedf6f200fe647bf179e04ace9e39b852b7dfa6a3d7296070c47"],
    ercTokens : [
        "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913", 
        "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb"],
    UNISWAP_ROUTER_ADDRESS: "0x2626664c2603336E57B271c5C0b26F421741e481",
    UNIVERSAL_ROUTER_ADDRESS: "0x198EF79F1F515F02dFE9e3115eD9fC07183f02fC",
    QUOTER_ADDRESS: "0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a",
    WETH_ADDRESS: "0x4200000000000000000000000000000000000006",
    CHAIN_ID: 8453,
    availableTasks1 : [
        
        {
            name: 'Wait for sec',
            functionToCall: 'waitForSeconds',
            inputs: ['seconds']
        },
        
        {
            name: 'Transfer ERC20',
            functionToCall: 'transferERC20',
            inputs: ['recipientAddress', 'amount', 'tokenAddress']
        },
        {
            name: 'Transfer ETH',
            functionToCall: 'transferETH',
            inputs: ['recipientAddress', 'amount']
        },
        {
            name: 'Swap ETH To Token',
            functionToCall: 'swapEthToToken',
            inputs: ['tokenAddress', 'amountIn']
        },
        {
            name: 'Swap Token To Eth',
            functionToCall: 'swapTokenToEth',
            inputs: ['tokenAddress', 'amount']
        }
    ],
    availableTasks : [
        
        {
            name: 'Wait for sec',
            functionToCall: 'waitForSeconds',
            inputs: ['seconds']
        },
        {
            name: 'Swap ETH To Token',
            functionToCall: 'swapEthToToken',
            inputs: ['tokenAddress', 'amountIn']
        },
        {
            name: 'Swap Token To Eth',
            functionToCall: 'swapTokenToEth',
            inputs: ['tokenAddress', 'amount']
        }
    ],
    networks: {
        Base: 'https://base-rpc.publicnode.com',
        PolygonMumbai: 'https://polygon-mumbai-bor-rpc.publicnode.com',
        PolygonAmoy: 'https://polygon-amoy.infura.io/v3/67eaae796961453c85021868c5da08d1',
        Mainnet: 'https://mainnet.infura.io/v3/9f1e5354e01044d18b20e08585da8330',
    },
};
export default SETTINGS;