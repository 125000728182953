
import { ERC20_ABI } from "../abis/erc20"; // Adjust the path as necessary

import axios from 'axios';
import { ethers } from 'ethers';

const SWAP_ROUTER_02_ADDRESS = "0x2626664c2603336E57B271c5C0b26F421741e481";
const SWAP_ROUTER_02_ABI = [
  // Minimal ABI for Uniswap V3 swap
  "function exactInputSingle(tuple(address tokenIn, address tokenOut, uint24 fee, address recipient, uint256 deadline, uint256 amountIn, uint256 amountOutMinimum, uint160 sqrtPriceLimitX96)) payable returns (uint256 amountOut)",
];

const UNISWAP_V2_ROUTER_ADDRESS = "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24";
const UNISWAP_V2_ROUTER_ABI = [
  // Minimal ABI for Uniswap V2 swap
  "function swapExactETHForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)",
  "function swapExactTokensForETH(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)",
  "function approve(address spender, uint value) public returns (bool)"
];



export const waitForSeconds = (seconds) => {
    return new Promise(resolve => {
        console.log(`Waiting ${seconds} second(s)...`);
        setTimeout(() => {
            console.log(`Waited ${seconds} second(s).`);
            resolve();
        }, seconds * 1000);
    });
};







  export const approveERC20 = async (wallet, tokenAddress, spenderAddress, amount) => {
    const provider = new ethers.providers.JsonRpcProvider(wallet.networkUrl);
    const signer = new ethers.Wallet(wallet.privateKey, provider);
    const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, signer);

    // Ethers.js handles BigNumber differently. Use ethers.utils.parseUnits for numeric values.
    const amountToApprove = ethers.utils.parseUnits(amount.toString(), await tokenContract.decimals());
    const tx = await tokenContract.approve(spenderAddress, amountToApprove);
    await tx.wait();
    console.log(`Approved ${amount} tokens for spender ${spenderAddress}`);
};





export const swapEthToToken = async (_wallet, tokenToBuy, amountInEther) => {
  console.log("START swapEthToToken");
  await _swapEthToTokenV2(_wallet, tokenToBuy, amountInEther)
  .then(() => console.log("Swap ETH to Token call complete"))
  .catch(err => console.error(err));
  console.log("STOP swapEthToToken");
};

async function _swapEthToTokenV2(_wallet, tokenAddress, amountInEther) {
  // Initialize ethers provider
  //const provider = new ethers.providers.JsonRpcProvider("https://mainnet.base.org");
  const provider = new ethers.providers.JsonRpcProvider("https://mainnet.base.org", {
    name: 'base',
    chainId: 8453,
  });
  const wallet = new ethers.Wallet(_wallet.privateKey, provider);
  console.log(_wallet);
  const router = new ethers.Contract(
    UNISWAP_V2_ROUTER_ADDRESS,
    UNISWAP_V2_ROUTER_ABI,
    wallet
  );

  const amountInWei = ethers.utils.parseEther(amountInEther.toString());
  const deadline = Math.floor(Date.now() / 1000) + 60 * 10; // 10 minutes from now

  const path = ["0x4200000000000000000000000000000000000006", tokenAddress];

  try {
    const tx = await router.swapExactETHForTokens(
      0, // amountOutMin: set to 0 for simplicity; consider setting a minimum amount to avoid slippage
      path,
      _wallet.address,
      deadline,
      { value: amountInWei }
    );

    await tx.wait();
    console.log("Swap ETH to Token successful");
  } catch (error) {
    console.error("Error during swap:", error);
  }
}



export const swapTokenToEth = async (_wallet, tokenAddress, amount) => {
  console.log("START swapTokenToEth");
  
  await _swapTokenToEthV2(_wallet, tokenAddress, amount)
  .then(() => console.log("Swap ETH to Token call complete"))
  .catch(err => console.error(err));

  console.log("STOP swapTokenToEth");
};

async function _swapTokenToEthV2(_wallet, tokenAddress, amountInTokens) {
  // Initialize ethers provider
  const provider = new ethers.providers.JsonRpcProvider("https://mainnet.base.org", {
    name: 'base',
    chainId: 8453,
  });
  const wallet = new ethers.Wallet(_wallet.privateKey, provider);

  const router = new ethers.Contract(
    UNISWAP_V2_ROUTER_ADDRESS,
    UNISWAP_V2_ROUTER_ABI,
    wallet
  );

  const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, wallet);
  const amountInWei = ethers.utils.parseUnits(amountInTokens.toString(), 18); // Assuming token has 18 decimals
  const deadline = Math.floor(Date.now() / 1000) + 60 * 10; // 10 minutes from now

  const path = [tokenAddress, ethers.constants.AddressZero];

  try {
    // Check the allowance first
    const allowance = await tokenContract.allowance(wallet.address, UNISWAP_V2_ROUTER_ADDRESS);
    if (allowance.lt(amountInWei)) {
      // Approve the Uniswap router to spend the tokens if necessary
      await tokenContract.approve(UNISWAP_V2_ROUTER_ADDRESS, amountInWei);
    }

    const tx = await router.swapExactTokensForETH(
      amountInWei,
      0, // amountOutMin: set to 0 for simplicity; consider setting a minimum amount to avoid slippage
      path,
      _wallet.address,
      deadline
    );

    await tx.wait();
    console.log("Swap Token to ETH successful");
  } catch (error) {
    console.error("Error during swap:", error);
  }
}



export const swapEthToToken1 = async (_wallet, tokenToBuy, amountInEther) => {
  console.log("START swapEthToToken");
  console.log(await fetchSwapData(_wallet.address, tokenToBuy, amountInEther));

  console.log("STOP swapEthToToken");
};

async function fetchSwapData(senderAddress, tokenOut, amount) {
  // Construct the URL with query parameters
  const queryParams = new URLSearchParams({ senderAddress, tokenOut, amount }).toString();
  const url = `http://localhost:3001/swaps/swapEthToToken?${queryParams}`;

  try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok.');
      
      const data = await response.json();
      console.log(data); // Process the response data as needed
  } catch (error) {
      console.error('Failed to fetch:', error);
  }
}
export const swapTokenToEth1 = async (_wallet, tokenAddress, amount) => {
  console.log("START swapTokenToEth");
  
  const queryParams = new URLSearchParams({ senderAddress: _wallet.address, tokenIn: tokenAddress, amount }).toString();
  const url = `http://localhost:3001/swaps/swapTokenToEth?${queryParams}`;

  try {
    const response = await axios.get(url);
    console.log(response.data); // Process the response data as needed
  } catch (error) {
    console.error('Failed to fetch:', error);
  }


  console.log("STOP swapTokenToEth");
};




  

  export const transferERC20 = async (_wallet, tokenAddress, recipientAddress, amount) => {
    console.log("START transferERC20");
    
    // Initialize a provider
    const provider = new ethers.providers.JsonRpcProvider(_wallet.networkUrl);

    // Create a wallet/signer
    const signer = new ethers.Wallet(_wallet.privateKey, provider);

    // Create a contract instance
    const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, signer);

    try {
        // Fetch the decimals for the token to calculate the correct amount
        const decimals = await tokenContract.decimals();

        // ethers.js handles BigNumber differently, use ethers.utils.parseUnits for conversion
        const amountInSmallestUnit = ethers.utils.parseUnits(amount.toString(), decimals);

        // Perform the transfer
        const tx = await tokenContract.transfer(recipientAddress, amountInSmallestUnit);
        await tx.wait(); // Wait for the transaction to be mined

        console.log(`Transaction hash: ${tx.hash}`);
    } catch (error) {
        console.error("Error in transferERC20:", error);
    }

    console.log("DONE transferERC20");
};



export const transferETH = async (_wallet, recipientAddress, amount) => {
  console.log("START transferETH");

  // Initialize a provider
  const provider = new ethers.providers.JsonRpcProvider(_wallet.networkUrl);

  // Create a wallet/signer
  const signer = new ethers.Wallet(_wallet.privateKey, provider);

  try {
      // Convert amount to wei
      const amountInWei = ethers.utils.parseEther(amount.toString());

      // Send the transaction
      const tx = await signer.sendTransaction({
          to: recipientAddress,
          value: amountInWei,
          // Ethers.js automatically estimates gas if not provided,
          // but you can also explicitly set it like so:
          // gasLimit: ethers.utils.hexlify(21000),
      });

      // Wait for the transaction to be mined
      await tx.wait();

      console.log(`Transaction hash: ${tx.hash}`);
  } catch (error) {
      console.error("Error in transferETH:", error);
  }

  console.log("DONE transferETH");
};






