import React from 'react';
import { ListGroup } from 'react-bootstrap';

const Logger = ({ logs }) => {
  return (
    <ListGroup className="loggerContainer">
      {logs.map((log, index) => (
        <ListGroup.Item key={index}>
          <small className="text-muted">{log.timestamp}</small> - {log.message}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default Logger;