import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Accordion, Card, ListGroup, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../settings";
import { ethers } from 'ethers';


import WalletManager from './WalletManager';
import logo from '../logo.png';
import TaskForm from './TaskForm';
import TaskList from './TaskList';
import Logger from './Logger'; // Import the Logger component


import { waitForSeconds, transferERC20, transferETH, swapEthToToken, swapTokenToEth } from './EthFunctions'; // Adjust the path as necessary
const myWalletsPks = SETTINGS.myWalletsPks;
const ercTokens = SETTINGS.ercTokens;

const Dashboard = () => {
    const [wallets, setWallets] = useState([]);
    const [batchTasks, setBatchTasks] = useState([]);
    const [logs, setLogs] = useState([]);
    const [isStarted, setIsStarted] = useState(false);

    const [inputPrivateKey, setInputPrivateKey] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState(Object.values(SETTINGS.networks)[0]);
    const [openWalletDetails, setOpenWalletDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const provider = new ethers.providers.JsonRpcProvider(selectedNetwork);

    useEffect(() => {
        loadWallets();
    }, []); 


    const loadWallets = async () => {
      //const response = await axios.get('http://localhost:3001/wallets');
      
        let walletsArr = [];
        for (let i = 0; i < SETTINGS.myWalletsPks.length; i++) {
            const pk = SETTINGS.myWalletsPks[i];
            const wallet = new ethers.Wallet(pk, provider);
            
            const balanceWei = await provider.getBalance(wallet.address);
            const balanceEth = ethers.utils.formatEther(balanceWei);
            
            let walletObj = { address: wallet.address, privateKey: pk, balance: parseFloat(balanceEth).toFixed(5), networkUrl: selectedNetwork, tokens: [] };

            for (let tokenAddress of ercTokens) {
                const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
                const decimals = await tokenContract.decimals();
                const symbol = await tokenContract.symbol(); // Fetching the token symbol
                const tokenBalanceWei = await tokenContract.balanceOf(wallet.address);
                
                const divisor = new BigNumber(10).pow(decimals);
                const tokenBalance = new BigNumber(tokenBalanceWei.toString()).dividedBy(divisor);
    
                walletObj.tokens.push({
                    tokenAddress,
                    symbol, // Including the symbol in the object
                    balance: tokenBalance.toFixed(4)
                });
            }
    
            walletsArr.push(walletObj);
        }
        
        setWallets(walletsArr);
        setIsLoading(false);
       
        console.log("Loaded " + myWalletsPks.length + " wallets with ERC20 token balances and symbols");
    };


    useEffect(() => {
        // Override console.log
        const originalConsoleLog = console.log;
        console.log = function (message) {
          let formattedMessage = message;
          if (typeof message === 'object') {
            formattedMessage = JSON.stringify(message, null, 2); // Convert object to string
          }
          originalConsoleLog(message);
          setLogs(prevLogs => [
            { message: formattedMessage, timestamp: new Date().toLocaleTimeString() },
            ...prevLogs
          ]);
        };
        return () => (console.log = originalConsoleLog); // Restore original console.log on cleanup
      }, []);



  

    const generateBatchTasks =  () => {
        let batchArr = [];
        for(let i=0; i< 2; i++){
            const batchList = _generateBatchTasks();
            batchArr = batchArr.concat(batchList);
        }
        
        return batchArr;
        
    }
    const _generateBatchTasks = () => {
      
        const batchTasksArr = [];
        wallets.forEach(wallet => {
          // Ensure the wallet has tokens with balance
          const validTokens = wallet.tokens.filter(token => parseFloat(token.balance) >= 0);
          if (validTokens.length > 0) {
            // Randomly select a task (excluding 'Wait for sec')
            const tasksExcludingWait = SETTINGS.availableTasks.filter(task => task.functionToCall !== 'waitForSeconds');
            const selectedTask = tasksExcludingWait[Math.floor(Math.random() * tasksExcludingWait.length)];
      
            // Generate task based on type
            let taskInputs = {};
            switch (selectedTask.functionToCall) {
              case 'transferERC20':
                const selectedToken = validTokens[Math.floor(Math.random() * validTokens.length)];
                const percentage = Math.random() * (0.20 - 0.05) + 0.05; // 5% to 20%
                const amount = selectedToken.balance * percentage;
                taskInputs = {
                  recipientAddress: wallet.address, // Example recipient
                  amount: amount.toFixed(4),
                  tokenAddress: selectedToken.tokenAddress
                };
                break;
              case 'swapEthToToken':
                const tokenToBuy = wallet.tokens[Math.floor(Math.random() * wallet.tokens.length)];
                const buyPercentage = Math.random() * (0.20 - 0.05) + 0.05; // 5% to 20%
                const buyAmount = wallet.balance* buyPercentage;
                taskInputs = {
                  tokenAddress: tokenToBuy.tokenAddress,
                  amountIn: buyAmount.toFixed(4)
                };
                break;
              case 'swapTokenToEth':
                const tokenToSell = validTokens[Math.floor(Math.random() * validTokens.length)];
                const sellPercentage = Math.random() * (0.20 - 0.05) + 0.05; // 5% to 20%
                const sellAmount = tokenToSell.balance * sellPercentage;
                taskInputs = {
                  tokenAddress: tokenToSell.tokenAddress,
                  amount: sellAmount.toFixed(4)
                };
                break;
              case 'transferETH':
                const ethPercentage = Math.random() * (0.20 - 0.05) + 0.05;
                const ethAmount = wallet.balance * ethPercentage;
                taskInputs = {
                  recipientAddress: wallet.address, // Example recipient
                  amount: ethAmount.toFixed(4)
                };
                break;
              // Handle other cases as needed
            }
      
            // Add the selected task
            const newTask = {
              name: selectedTask.name,
              functionToCall: selectedTask.functionToCall,
              wallet: wallet,
              inputs: taskInputs,
              status: 0
            };
            batchTasksArr.push(newTask);
            // Add waitForSeconds task
            const waitSeconds = Math.floor(Math.random() * (40 - 20) + 20); // 20 to 40 seconds
            const newWaitTask ={
              name: 'Wait for',
              functionToCall: 'waitForSeconds',
              wallet: wallet,
              inputs: {
                seconds: waitSeconds
              },
              status: 0
            };

            batchTasksArr.push(newWaitTask);
          }
        });
        return batchTasksArr;
        
      };
      
      const executeBatchTasks = async () => {
        // Check if there are no tasks, generate them first
    
        let newTasks = generateBatchTasks(); // Ensure this awaits so tasks are generated before executing
        
        setIsStarted(true);
        setBatchTasks(newTasks);
        console.log("Starting batch tasks...");
        for (let i = 0; i < newTasks.length; i++) {
            const task = newTasks[i];
    
            // Update task status to 'executing' (1)
            updateTaskStatus(i, 1);
    
            try {
                switch (task.functionToCall) {
                    case 'transferERC20':
                        await transferERC20(task.wallet, task.inputs.tokenAddress, task.inputs.recipientAddress, task.inputs.amount);
                        break;
                    case 'transferETH':
                        await transferETH(task.wallet, task.inputs.recipientAddress, task.inputs.amount);
                        break;
                    case 'waitForSeconds':
                        await waitForSeconds(task.inputs.seconds);
                        break;
                    case 'swapEthToToken':
                        await swapEthToToken(task.wallet, task.inputs.tokenAddress, task.inputs.amountIn);
                        break;
                    case 'swapTokenToEth':
                        await swapTokenToEth(task.wallet, task.inputs.tokenAddress, task.inputs.amount);
                        break;
                    // Include other cases as necessary
                }
                // On successful execution, update task status to 'done' (2)
                newTasks[i].status = 2;
                updateTaskStatus(i, 2);
            } catch (error) {
                console.error(`Error executing task ${i}:`, error);
                // Optionally handle errors, such as setting a 'failed' status or retrying
                updateTaskStatus(i, 3);
                newTasks[i].status = 3;
            }
        }
    
        console.log("Batch tasks completed.");
    
        // Here, decide if new tasks need to be generated immediately after the current batch completes
        // This could be based on a condition or simply to keep the batch execution ongoing
        // For demonstration, assuming new tasks should be generated and executed
        // Clear the current batch
        
        setBatchTasks([]);
        // Optionally, introduce a slight delay or conditions before generating new tasks
        await loadWallets();
        await waitForSeconds(5);
        
        executeBatchTasks(); // Start executing the new batch
    };
    
    
    const updateTaskStatus = (taskIndex, status) => {
        setBatchTasks(batchTasks => batchTasks.map((task, index) => {
            if (index === taskIndex) {
                return { ...task, status: status };
            }
            return task;
        }));
    };

    // Task execution functions (placeholders for actual logic)
    


    return (
        <Container fluid="md" className='mainContainer'>
            <strong className="titleHeader">
              <img src={logo}  /> 
            </strong>
            <Row className="justify-content-md-center">
            <Col xs={12} >
            <Row className="mb-3">
          <Col xs={12}>
            <Form>
              {/* Condensed form layout */}
              <Row>
               
                <Col sm={12}>
                    <center>
                {isLoading ? ( <Spinner animation="border" style={{color:"#FFFFFF", width:"100px", height:"100px", marginTop:"250px", marginBottom:"200px"}} size="lg" />) : (
                   <></>
                 )}  
                  </center>
                </Col>
                
              </Row>
              
            </Form>
          </Col>
        
      
            </Row>
          
     
</Col>
</Row>
{!isLoading ? (
<Row className="justify-content-md-center">

<Col xs={12} md={6}>


<div className="dashboardContainer">
<strong className="taskName">Batch</strong>
<hr />
{!isStarted ? (
   <center>
    <br />
    <br />
     <Button className='walletButton' style={{width:"100%", margin:"0 auto", padding:"20px"}} onClick={executeBatchTasks}>Start</Button>
     <br />
     <br />
     <br />
     </center>
    ):(<></>)}


<div style={{maxHeight:"500px", overflow:"auto", display:"block"}}>

    <TaskList batchTasks={batchTasks} />
    </div>
    </div>
</Col>
<Col xs={12} md={6}>
          <div className="dashboardContainer">
          <strong className="taskName">Wallets</strong>
          <hr />
    <Table className=''>
       
        <tbody>
            {wallets.map((wallet, index) => (
                <tr key={index}>
                    <td>
                      <small><a href={`https://basescan.org/address/${wallet.address}`} target='_blank'>{wallet.address}</a><br />
                    {wallet.networkUrl}</small><br />
                    <small className='balanceWallet'><strong>ETH:</strong> {wallet.balance}</small>
                        {wallet.tokens.map((token, tokenIndex) => (
                           
                               <small key={tokenIndex} className='balanceWallet'><strong>{token.symbol}:</strong> {token.balance}</small> 
                           
                        ))}
                    </td>
                </tr>
            ))}
        </tbody>
    </Table>
   
</div>
<div className="dashboardContainer">
<strong className="taskName">Logs</strong>
<hr />
<div style={{maxHeight:"500px", overflow:"auto", display:"block"}}>

<Logger logs={logs} />
</div>
</div>
                </Col>
<Col xs={12} md={12}>

</Col>
            </Row>):(<></>)}
          
        </Container>
    );
};

export default Dashboard;
