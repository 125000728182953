
import React, { useState, useEffect } from 'react'; // Import useEffect
import { Container, Row, Col, Form, Button, Accordion, Card, ListGroup, Spinner } from 'react-bootstrap';

import BigNumber from 'bignumber.js';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../settings";
import { ethers } from 'ethers';
const myWalletsPks = SETTINGS.myWalletsPks;
const ercTokens = SETTINGS.ercTokens;

const WalletManager = ({ onWalletChange, networks }) => {
    const [inputPrivateKey, setInputPrivateKey] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState(Object.values(networks)[0]);
    const [wallets, setWallets] = useState([]);
    const [openWalletDetails, setOpenWalletDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const provider = new ethers.providers.JsonRpcProvider(selectedNetwork);

    useEffect(() => {
        loadWallets();
    }, []); 


    const loadWallets = async () => {
        setIsLoading(true);
        let walletsArr = [];
        for (let i = 0; i < myWalletsPks.length; i++) {
            const pk = myWalletsPks[i];
            const wallet = new ethers.Wallet(pk, provider);
            
            const balanceWei = await provider.getBalance(wallet.address);
            const balanceEth = ethers.utils.formatEther(balanceWei);
            
            let walletObj = { address: wallet.address, privateKey: pk, balance: balanceEth, networkUrl: selectedNetwork, tokens: [] };

            for (let tokenAddress of ercTokens) {
                const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
                const decimals = await tokenContract.decimals();
                const symbol = await tokenContract.symbol(); // Fetching the token symbol
                const tokenBalanceWei = await tokenContract.balanceOf(wallet.address);
                
                const divisor = new BigNumber(10).pow(decimals);
                const tokenBalance = new BigNumber(tokenBalanceWei.toString()).dividedBy(divisor);
    
                walletObj.tokens.push({
                    tokenAddress,
                    symbol, // Including the symbol in the object
                    balance: tokenBalance.toString()
                });
            }
    
            walletsArr.push(walletObj);
        }
        
        setWallets(walletsArr);
        onWalletChange(walletsArr);
        setIsLoading(false);
        console.log("Loaded " + myWalletsPks.length + " wallets with ERC20 token balances and symbols");
    };

    

    return (
        <Container className="dashboardContainer">
        <Row className="mb-3">
          <Col xs={6}>
            <Form>
              {/* Condensed form layout */}
              <Row>
               
                <Col sm={2}>
                {isLoading ? ( <Spinner animation="border" size="sm" />) : (
                    <Button className="walletButton" onClick={loadWallets}>Load</Button>
                 )}  
                  
                </Col>
                <Col sm={2}>
                  
                 {/* <Button className="walletButton" onClick={createWallet}>New</Button> */}
                </Col>
              </Row>
              
            </Form>
          </Col>
        
          <Col xs={6}>
                <Accordion defaultActiveKey="0">
    <Card>
        <Accordion.Header onClick={() => setOpenWalletDetails(prevState => !prevState)}>
            Wallets 
        </Accordion.Header>
        <Accordion.Body eventKey="0">
            <ListGroup className="listGroup">
                {wallets.map((wallet, index) => (
                    <ListGroup.Item key={index} className="listGroupItem">
                        <div className="walletDetails">
                            <strong>Address:</strong> {wallet.address}
                        </div>
                        <div className="walletDetails">
                            <strong>Network:</strong> {wallet.networkUrl}
                        </div>
                        <div className="walletDetails">
                            <strong>Balance:</strong> {wallet.balance} ETH
                        </div>
                        {wallet.tokens && wallet.tokens.map((token, tokenIndex) => (
                            <div key={tokenIndex} className="walletDetails">
                                <strong>{token.symbol}:</strong> {token.tokenAddress} <strong>Balance:</strong> {token.balance}
                            </div>
                        ))}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Accordion.Body>
    </Card>
</Accordion>
                </Col>
            </Row>
      </Container>
    );
};

export default WalletManager;
